import React, { useState } from 'react';
import { Link, useLocation } from 'react-router-dom';

function Header() {
  const location = useLocation();
  const [activeLink, setActiveLink] = useState(location.pathname);

  const handleLinkClick = (path) => {
    setActiveLink(path);
  };

  return (
    <header>
      <img src="logo.svg" alt="Logo" className='logo'/> {/* Assuming the logo is directly in the public folder */}
      <nav>
        <ul>
          <li>
            <Link
              to="/"
              className={activeLink === '/' ? 'active' : ''}
              onClick={() => handleLinkClick('/')}
            >
              About Me
            </Link>
          </li>
          <li>
            <Link
              to="/projects"
              className={activeLink === '/projects' ? 'active' : ''}
              onClick={() => handleLinkClick('/projects')}
            >
              Projects
            </Link>
          </li>
          <li>
            <Link
              to="/contact"
              className={activeLink === '/contact' ? 'active' : ''}
              onClick={() => handleLinkClick('/contact')}
            >
              Contact
            </Link>
          </li>
        </ul>
      </nav>
    </header>
  );
}

export default Header;