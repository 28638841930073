// AboutMe.js
import React, {useEffect, useState} from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faDocker, faJenkins, faGit, faGithub, faGitlab, faRedhat, faMicrosoft, faCloudflare, faLinux, faJava, faNode, faWindows, faUncharted, faPython, faSearchengin, faSistrix, faAws, faCodepen, faCloudscale, faApple, faAndroid, faUnity, faThemeco } from '@fortawesome/free-brands-svg-icons';
import { Carousel } from 'react-responsive-carousel';
import "react-responsive-carousel/lib/styles/carousel.min.css"; // requires a loader

const iconMapping = {
  faDocker,
  faJenkins,
  faGit,
  faGithub,
  faGitlab,
  faRedhat,
  faMicrosoft,
  faCloudflare,
  faLinux,
  faJava,
  faNode,
  faWindows,
  faUncharted,
  faPython,
  faSearchengin,
  faSistrix,
  faAws,
  faCodepen,
  faCloudscale,
  faApple,
  faAndroid,
  faUnity,
  faThemeco
};

function AboutMe() {
  
  const [technologies, setTechnologies] = useState([]);
  const [experience, setExperience] = useState([]);
  const [images, setImages] = useState([]);
  const [isGalleryOpen, setIsGalleryOpen] = useState(false);

  useEffect(() => {
    fetch('https://api.chiscari.ro/technologies')
      .then(response => response.json())
      .then(data => {
        const updatedData = data.map(item => ({
          ...item,
          icon: iconMapping[item.icon]
        }));
        setTechnologies(updatedData);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }, []);

  useEffect(() => {
    if (technologies.length > 0) {
      document.querySelectorAll('.tech-item').forEach(item => {
        item.addEventListener('mousemove', function(e) {
          let x = e.pageX - e.target.offsetLeft;
          let y = e.pageY - e.target.offsetTop;
          let gradientX = x / e.target.clientWidth * 100;
          let gradientY = y / e.target.clientHeight * 100;
          e.target.style.setProperty('--x', `${gradientX}%`);
          e.target.style.setProperty('--y', `${gradientY}%`);
        });
      });
    }
  }, [technologies]);

  useEffect(() => {
    fetch('https://api.chiscari.ro/jobs')
      .then(response => response.json())
      .then(data => {
        // data is the array of job experiences
        // sort the data in reverse order
        const sortedData = data.sort((a, b) => b - a).reverse();
        setExperience(sortedData);
      })
      .catch(error => {
        console.error('Error:', error);
      });
  }, []);

  const openPhotoGallery = async () => {
    if (isGalleryOpen) {
      setIsGalleryOpen(false);
      setImages([]);
    } else {
      const url = 'https://frwnfslc9lrx.objectstorage.eu-frankfurt-1.oci.customer-oci.com/p/FEKDgoVN44JOmbxHyblRX7j13QifhRssC-y0exipnFlFse2ATWkaVemTeN8CMe61/n/frwnfslc9lrx/b/photos/o/';
      const response = await fetch(url);
      const data = await response.json();
      const imageUrls = data.objects.map(obj => `${url}${obj.name}`);
      setImages(imageUrls);
      setIsGalleryOpen(true);
    }
  };

  
  return (
    <section>
      <h2>About Me</h2>
      <img className="profile-picture" src={process.env.PUBLIC_URL + '/myPicture.jpg'} alt="Alexandru Chiscari" />
      <p>
        Hi, I'm Alexandru Chiscari, a DevOps specialist with a focus on on-premises containerization. 
        I come from a graphic design background, which has given me a unique perspective on the 
        intersection of design and technology. In addition to my technical skills, I have a deep 
        passion for photography. This combination of experiences and interests allows me to approach 
        problems from a unique angle, and I'm always excited to take on new challenges in the tech 
        world.
      </p>
      <p>Here are some photos I took:</p>
      <div className="button-container">
      <button onClick={openPhotoGallery}>Photo Gallery</button>
      </div>
      {isGalleryOpen && images.length > 0 && (
        <div className={`lightbox`}>
          <button className="lightbox-portfolio" onClick={() => window.open('https://portfolio.chiscari.ro', '_blank')}>Open Photo Portfolio</button>
          <button className="lightbox-close" onClick={() => setIsGalleryOpen(false)}>Close</button>
          <div className="lightbox-content">
            <Carousel>
            {images.map((imageUrl, index) => (
              <div key={index} className="carousel-item">
                <img className="carousel-image" src={imageUrl} alt="Gallery" />
              </div>
            ))}
            </Carousel>
          </div>
        </div>
      )}

      <h2>Technologies I use</h2>
      <div className="tech-grid">
        {technologies.map((tech, index) => (
          <div key={index} className="tech-item">
            <FontAwesomeIcon icon={tech.icon } size="3x" />
            <p>{tech.name}</p>
          </div>
        ))}
      </div>
      <h2>Design principles</h2>
      <p>
        I am a firm believer in the power of simplicity in design. To me, a simple design is not just about aesthetics, but also about efficiency. By eliminating unnecessary elements, we can focus on what truly matters and make the user experience smoother and more intuitive. This approach not only makes the design more appealing but also improves the overall performance of the system. I strive to apply this principle in all my projects, always aiming to create solutions that are not only effective but also efficient and easy to use.
      </p>
      <h2>Places I've worked</h2>
      {experience.map((job, index) => (
      <div key={index} className="job-item">
        <h3>{job.title} at {job.company}</h3>
        <div style={{ display: 'flex', justifyContent: 'space-between' }}>
          <h4>{job.duration}</h4>
          <h4>{job.location}</h4>
        </div>
        <p>{job.description}</p>
      </div>
    ))}
    </section>
  );
}

export default AboutMe;
